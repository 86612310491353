define("discourse/plugins/discourse-topic-trade-buttons/discourse/initializers/extend-topic-for-sold-button", ["exports", "discourse/lib/plugin-api", "discourse/models/topic", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _topic, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function initializeWithApi(api) {
    var _dec, _dec2, _dec3, _dec4, _obj, _init, _init2, _init3, _init4;
    const currentUser = api.getCurrentUser();
    _topic.default.reopen((_dec = (0, _decorators.default)("archived", "custom_fields.enable_sold_button"), _dec2 = (0, _decorators.default)("archived", "custom_fields.enable_purchased_button"), _dec3 = (0, _decorators.default)("archived", "custom_fields.enable_exchanged_button"), _dec4 = (0, _decorators.default)("archived", "custom_fields.enable_cancelled_button"), (_obj = {
      canTopicBeMarkedAsSold: function () {
        const enable_sold_button = this.category_enable_sold_button ? this.category_enable_sold_button.toLowerCase() === "true" : false;
        return !this.isPrivatemessage && currentUser && currentUser.id === this.user_id && this.siteSettings.topic_trade_buttons_enabled && enable_sold_button && !this.get("archived");
      },
      canTopicBeMarkedAsPurchased: function () {
        const enable_purchased_button = this.category_enable_purchased_button ? this.category_enable_purchased_button.toLowerCase() === "true" : false;
        return !this.isPrivatemessage && currentUser && currentUser.id === this.user_id && this.siteSettings.topic_trade_buttons_enabled && enable_purchased_button && !this.get("archived");
      },
      canTopicBeMarkedAsExchanged: function () {
        const enable_exchanged_button = this.category_enable_exchanged_button ? this.category_enable_exchanged_button.toLowerCase() === "true" : false;
        return !this.isPrivatemessage && currentUser && currentUser.id === this.user_id && this.siteSettings.topic_trade_buttons_enabled && enable_exchanged_button && !this.get("archived");
      },
      canTopicBeMarkedAsCancelled: function () {
        const enable_cancelled_button = this.category_enable_cancelled_button ? this.category_enable_cancelled_button.toLowerCase() === "true" : false;
        return !this.isPrivatemessage && currentUser && currentUser.id === this.user_id && this.siteSettings.topic_trade_buttons_enabled && enable_cancelled_button && !this.get("archived");
      }
    }, (_applyDecoratedDescriptor(_obj, "canTopicBeMarkedAsSold", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "canTopicBeMarkedAsSold"), _init = _init ? _init.value : undefined, {
      enumerable: true,
      configurable: true,
      writable: true,
      initializer: function () {
        return _init;
      }
    }), _obj), _applyDecoratedDescriptor(_obj, "canTopicBeMarkedAsPurchased", [_dec2], (_init2 = Object.getOwnPropertyDescriptor(_obj, "canTopicBeMarkedAsPurchased"), _init2 = _init2 ? _init2.value : undefined, {
      enumerable: true,
      configurable: true,
      writable: true,
      initializer: function () {
        return _init2;
      }
    }), _obj), _applyDecoratedDescriptor(_obj, "canTopicBeMarkedAsExchanged", [_dec3], (_init3 = Object.getOwnPropertyDescriptor(_obj, "canTopicBeMarkedAsExchanged"), _init3 = _init3 ? _init3.value : undefined, {
      enumerable: true,
      configurable: true,
      writable: true,
      initializer: function () {
        return _init3;
      }
    }), _obj), _applyDecoratedDescriptor(_obj, "canTopicBeMarkedAsCancelled", [_dec4], (_init4 = Object.getOwnPropertyDescriptor(_obj, "canTopicBeMarkedAsCancelled"), _init4 = _init4 ? _init4.value : undefined, {
      enumerable: true,
      configurable: true,
      writable: true,
      initializer: function () {
        return _init4;
      }
    }), _obj)), _obj)));
  }
  var _default = _exports.default = {
    name: "extend-topic-for-sold-button",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", initializeWithApi);
    }
  };
});