define("discourse/plugins/discourse-topic-trade-buttons/discourse/pre-initializers/extend-category-for-topic-trade-buttons", ["exports", "discourse/models/category", "discourse-common/utils/decorators"], function (_exports, _category, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "extend-category-for-topic-trade-buttons",
    before: "inject-discourse-objects",
    initialize() {
      var _dec, _dec2, _dec3, _dec4, _obj, _init, _init2, _init3, _init4;
      _category.default.reopen((_dec = (0, _decorators.default)("custom_fields.enable_sold_button"), _dec2 = (0, _decorators.default)("custom_fields.enable_purchased_button"), _dec3 = (0, _decorators.default)("custom_fields.enable_exchanged_button"), _dec4 = (0, _decorators.default)("custom_fields.enable_cancelled_button"), (_obj = {
        enable_sold_button: {
          get(enableField) {
            return enableField === "true";
          },
          set(value) {
            value = value ? "true" : "false";
            this.set("custom_fields.enable_sold_button", value);
            return value;
          }
        },
        enable_purchased_button: {
          get(enableField) {
            return enableField === "true";
          },
          set(value) {
            value = value ? "true" : "false";
            this.set("custom_fields.enable_purchased_button", value);
            return value;
          }
        },
        enable_exchanged_button: {
          get(enableField) {
            return enableField === "true";
          },
          set(value) {
            value = value ? "true" : "false";
            this.set("custom_fields.enable_exchanged_button", value);
            return value;
          }
        },
        enable_cancelled_button: {
          get(enableField) {
            return enableField === "true";
          },
          set(value) {
            value = value ? "true" : "false";
            this.set("custom_fields.enable_cancelled_button", value);
            return value;
          }
        }
      }, (_applyDecoratedDescriptor(_obj, "enable_sold_button", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "enable_sold_button"), _init = _init ? _init.value : undefined, {
        enumerable: true,
        configurable: true,
        writable: true,
        initializer: function () {
          return _init;
        }
      }), _obj), _applyDecoratedDescriptor(_obj, "enable_purchased_button", [_dec2], (_init2 = Object.getOwnPropertyDescriptor(_obj, "enable_purchased_button"), _init2 = _init2 ? _init2.value : undefined, {
        enumerable: true,
        configurable: true,
        writable: true,
        initializer: function () {
          return _init2;
        }
      }), _obj), _applyDecoratedDescriptor(_obj, "enable_exchanged_button", [_dec3], (_init3 = Object.getOwnPropertyDescriptor(_obj, "enable_exchanged_button"), _init3 = _init3 ? _init3.value : undefined, {
        enumerable: true,
        configurable: true,
        writable: true,
        initializer: function () {
          return _init3;
        }
      }), _obj), _applyDecoratedDescriptor(_obj, "enable_cancelled_button", [_dec4], (_init4 = Object.getOwnPropertyDescriptor(_obj, "enable_cancelled_button"), _init4 = _init4 ? _init4.value : undefined, {
        enumerable: true,
        configurable: true,
        writable: true,
        initializer: function () {
          return _init4;
        }
      }), _obj)), _obj)));
    }
  };
});